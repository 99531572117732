<template>
  <section class="top-menu-vue">
    <Menubar :model="items" />
    <div style="height: 0.2em; margin: 0; background-color: #eee">
      <ProgressBar
        v-if="progressBar"
        mode="indeterminate"
        style="height: 0.2em"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TabMenu from "primevue/tabmenu";
import router from "../router";
import { useStore, ActionTypes } from "../store";
import Toaster from "../helpers/Toaster";

@Options({
  components: {
    TabMenu,
  },
})
export default class Header extends Vue {
  private store = useStore();
  private items = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-home",
      to: "/store/dashboard",
    },
    {
      label: "Associate",
      icon: "pi pi-fw pi-user",
      items: [
        {
          label: "Schedule Assistant",
          icon: "pi pi-fw pi-clock",
          to: "/store/schedule",
        },
        {
          label: "Hours Approval",
          icon: "pi pi-fw pi-check-circle",
          to: "/store/hours-approval",
        },
      ],
    },
    {
      label: "Check in",
      icon: "pi pi-fw pi-check",
      to: "/check-in",
    },
    {
      label: "Login/Logout",
      icon: "pi pi-fw pi-lock",
      to: "/store/attendance",
    },
    {
      label: "Backroom",
      icon: "pi pi-fw pi-table",
      to: "/backroom",
    },
    {
      label: "Transactions",
      icon: "pi pi-fw pi-window-maximize",
      to: "/process/transactions",
    },
    {
      label: "Settings",
      icon: "pi pi-fw pi-cog",
      items: [
        {
          label: "Cash Register",
          icon: "pi pi-fw pi-money-bill",
          to: "/store/cash-register",
        },
        {
          label: "Initialization",
          icon: "pi pi-fw pi-plus-circle",
          to: "/store/initialization",
        },
        {
          label: "Store Expense",
          icon: "pi pi-fw pi-dollar",
          to: "/store/store-expense",
        },
        {
          label: "Reports",
          icon: "pi pi-fw pi-chart-bar",
          to: "/store/report",
        },
        {
          label: "Logout",
          icon: "pi pi-fw pi-power-off",
          command: () => this.logOut(),
        },
      ],
    },
  ];

  logOut() {
    const toast = new Toaster();

    const res = {
      alert: "info",
      msg: "Signout successfully",
    };

    toast.handleResponse(res);

    this.store.dispatch(ActionTypes.AUTH_LOGOUT, "");

    router.push({ path: "/login" });
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }
}
</script>

<style >
.top-menu-vue {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
}
.p-menubar {
  padding: 0;
  background-color: #fff;
  border-radius: 0px;
}
.p-menubar-root-list {
  margin: 0 auto !important;
  padding: 0 !important;
}

.p-menuitem-icon {
  color: #000 !important;
}

.p-menuitem-text {
  color: #000 !important;
}

.p-menuitem-link {
  background-color: #eee;
  border-radius: 0px;
  margin: 3px 2px;
  padding: 0.8rem !important;
}
</style>


import { Vue, Options } from "vue-class-component";
import { camelCase } from "lodash";
import moment from "moment";
import Transaction from "../service/Transaction";

@Options({
  components: {},
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.previewReceipt(obj.receiptID);
    },
  },
})
export default class CheckoutReceipt extends Vue {
  private txnService;
  private corporateType = "";
  private receiptAttr = {
    id: "",
    scheduleName: "",
    customer: "",
    phone: "",
    referalOrCorp: "",
    accountType: "",
    referalOrCorpId: "",
    description: "",
    defectReason: "",
    dueTime: "",
    dueDate: "",
    discountMethod: "",
    discount: 0,
    taxName: "",
    taxMethod: "",
    taxAmount: 0,
    taxAmountPrice: 0,
    totalQty: 0,
    totalBill: 0,
    invoiceServices: [
      {
        totalBill: 0,
        express: 0,
        qty: 0,
        checkStatus: 0,
        serviceName: 0,
        itemList: [
          {
            itemName: "",
            itemQty: "",
            price: "",
            tag3: "",
          },
        ],
        dueDate: 0,
        dueTime: 0,
        discountId: 0,
        discount: 0,
        discountName: 0,
        discountMethod: 0,
        tag1: 0,
        tag2: 0,
        description: 0,
        pickupName: '',
        customerSignature: '',
      },
    ],
  };

  created() {
    this.txnService = new Transaction();
  }

  previewReceipt(receiptID) {
    this.txnService.getInvDetails(receiptID).then((data) => {
      const receipt = this.camelizeKeys(data);
      this.receiptAttr = receipt.invoiceDetails[0];
      //this.getCorporateType(this.receiptAttr.referalOrCorpId);
    });
  }

  // getCorporateType(corporateID) {
  //   if (this.receiptAttr.referalOrCorp == "Corporate") {
  //     this.txnService.getCorporateType(corporateID).then((data) => {
  //       this.corporateType = data;
  //     });
  //   }
  // }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(amount) {
    amount = Number(amount);
    amount = amount.toFixed(2);
    return amount;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  serviceTotalBill() {
    let totalBill = 0;

    this.receiptAttr.invoiceServices.forEach((e) => {
      totalBill = totalBill + e.totalBill;
    });

    return totalBill.toFixed(2);
  }

  reverseReceipt(itemList)
  {
    const l = itemList.slice();
    return l.reverse();
  }
}


import { Options, Vue,mixins } from 'vue-class-component';
import Backroom from "../service/Backroom";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  components: {},
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {

      if(obj.status)
      {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    }
  },
  emits: ["updateEmployeeStatus"],
})
export default class EmployeeBackroom extends mixins(UtilityOptions){

    private productDialog = false;
    private loading = false;
    private receiptID = '';
    private associateList = [];
    private backroomService;

    created()
    {
      this.backroomService = new Backroom();
    }

    mounted()
    {
       // const fetchDate = "";
        //this.loadList(fetchDate);
    }

    openDialog()
    {
        this.productDialog = true;
        this.loading = true;
        this.backroomService.associateOrderPreview(this.receiptID).then((data) => {
        this.associateList = data;
        this.loading = false;
      });
    }

    closeDialogBox()
    {
        this.productDialog = false;
          this.$emit("updateEmployeeStatus");
    }
}

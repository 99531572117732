<template>
    <Dialog
    v-model:visible="productDialog"
    position="top"
    :closable="true"
    class="p-fluid  p-m-0 p-dialog-maximized"
    @hide="closeDialogBox"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-user"></i> Employee worked on order
      </h5>
    </template>
   <DataTable
          :value="associateList"
          :loading="loading"
          :paginator="false"
          :scrollable="true"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <Column field="service_name" header="Service Name"></Column>
          <Column
            field="employee_firstname"
            headerStyle="width: 200px"
            header="Assoicate Name"
          >
            <template #body="slotProps">
              {{ slotProps.data.employee_firstname }}
              {{ slotProps.data.employee_name }} (
              {{ slotProps.data.working_employee }} )
            </template>
          </Column>
          <Column field="start_time" header="Start Production Time">
           <template #body="slotProps">
                {{ formatTime(slotProps.data.start_time) }}
            </template>
          </Column>
          <Column field="start_date" header="Start Production Date">
              <template #body="slotProps">
                  {{ formatDate(slotProps.data.start_date) }}
              </template>
          </Column>
          <Column field="completed_time" header="Production Completed Time">
              <template #body="slotProps">
                  {{ formatTime(slotProps.data.completed_time) }}
              </template>
          </Column>
          <Column field="completed_date" header="Production Completed Date">
              <template #body="slotProps">
                  {{ formatDate(slotProps.data.completed_date) }}
              </template>
          </Column>
        </DataTable>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue,mixins } from 'vue-class-component';
import Backroom from "../service/Backroom";
import UtilityOptions from "../mixins/UtilityOptions";

@Options({
  components: {},
  props: {
    associateDetail: Object,
  },
  watch: {
    associateDetail(obj) {

      if(obj.status)
      {
        this.receiptID = obj.receiptID;
        this.openDialog();
      }
    }
  },
  emits: ["updateEmployeeStatus"],
})
export default class EmployeeBackroom extends mixins(UtilityOptions){

    private productDialog = false;
    private loading = false;
    private receiptID = '';
    private associateList = [];
    private backroomService;

    created()
    {
      this.backroomService = new Backroom();
    }

    mounted()
    {
       // const fetchDate = "";
        //this.loadList(fetchDate);
    }

    openDialog()
    {
        this.productDialog = true;
        this.loading = true;
        this.backroomService.associateOrderPreview(this.receiptID).then((data) => {
        this.associateList = data;
        this.loading = false;
      });
    }

    closeDialogBox()
    {
        this.productDialog = false;
          this.$emit("updateEmployeeStatus");
    }
}
</script>

<style scoped>
</style>


import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Customer from "../service/Customer";
import { RegionList, CustomerReviews } from "../pages/customers/ICustomers";
import moment from "moment";
import { useStore, ActionTypes } from "../store";

@Options({
  props: {
    customerDetail: Object,
  },
  watch: {
    customerDetail(obj) {
      if (obj.status == "openCustomer") {
        this.customers.customerId = obj.customerId;
        this.customers.branchId = obj.customerStoreID;
        this.customers.phone1 = this.selectedKeyword;
        this.openDialog();
      } else if (obj.status == "updateCustomer") {
        this.editIemDialog(obj.customerProfile);
      } else {
        this.dialogTitle = "";
      }
     
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateCustomerStatus"],
})
export default class CustomerDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle = "";
  private permission = "";
  private store = useStore();
  private customer;
  private managerList = [];
  private storeList = [];
  private customerReviews: CustomerReviews[] = [];
  private provinceList  = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrado" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexic" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];

  //private customerProfile = {};
  private hideDisableFields = false;

  private customerClass = [{ value: "Entry Level" }];

  private genderClass = [{ value: "Male" }, { value: "Female" }];

  private cusType = [
		{ value: "Regular" }
		];

  private customers = {
    id: "",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone1: this.selectedKeyword,
    homePhone: "",
    misc: "",
    customerType: { value: "Regular" },
    address: "",
    city: "",
    zipCode: "",
    complex: "",
    stateProvince: {},
    branchId: 0,
    provincialRate: "no",
    canadaRate: "no",
    exempt1: "",
    exempt2: "",
    keyTags: "",
    urgentMemo: "",
    birthday: "",
    gender: { value: "Female" },
    maleNeck: 0,
    maleFullChest: 0,
    maleFrontChestArea: 0,
    maleBackChest: 0,
    maleFullShoulderWidth: 0,
    maleBlazerSleeve: 0,
    maleBicep: 0,
    maleWrist: 0,
    maleTrouserOutseam: 0,
    maleThigh: 0,
    maleKnee: 0,
    maleHipSeat: 0,
    maleHalfBackLength: 0,
    femaleNeck: 0,
    femaleOverBrust: 0,
    femaleBrust: 0,
    femaleUnderBrust: 0,
    femaleWaist: 0,
    femaleHips: 0,
    femaleNeckHeal: 0,
    femaleArmLenght: 0,
    femaleShoulderSeam: 0,
    femaleBicep: 0,
    femaleForearm: 0,
    femaleWrist: 0,
    cusMood: "green",
    cusComments: "",
    customerReviews: this.customerReviewsList,
    class: { value: "Entry Level" },
    pointFeature: "true",
    promoNotification: "false",
    pointEarned: 0,
    emailNotification: "true",
    textMessage: "false",
    cusCredit: 0,
    ytdVisits: 0,
    ytdTotals: 0,
    totalVisits: 0,
    totalService: 0,
    totalDues: "",
    lastVisit: "",
    lastDues: 0,
    customerSince: "",
    customerServices: [],
    status: "Active",
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  get selectedKeyword() {
    return this.store.getters.getReceiptID;
  }

  formatAmount(value) {

    value = Number(value);

    let amount = 0;

    if (value != "") {
      amount = value.toFixed(2);
    }

    return Number(amount);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
  }

  //ADD REVIEWS
  addReview() {
    if (this.customers.cusComments == "") {
      this.toast.showWarning("Empty Comment!");
    } else {
      const review: CustomerReviews = {
        customerMood: this.customers.cusMood,
        note: this.customers.cusComments,
        visitDate: moment().format("YYYY-MM-DD"),
        visitTime: moment().format("HH:mm")
      };

      this.customerReviews.push(review);
      this.customers.cusComments = "";
      this.toast.showSuccess("Review added to apply changes please click save");
    }
  }

  //ADD CUSTOMER CREDIT
  addCustomerCredit() {
    if (this.customers.cusCredit == 0) {
      this.toast.showWarning("Please enter amount");
    } else {
      this.customers.pointEarned =
        Number(this.customers.pointEarned) + this.customers.cusCredit;
      this.customers.cusCredit = 0;
      this.toast.showSuccess("Store Credit added to apply changes please click save");
    }
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.customers.firstName.trim() && this.customers.lastName.trim() && this.customers.phone1.trim()) {
      if (this.customers.id != "") {
        this.customer.updateItem(this.customers,this.customerReviewsList).then((res) => {
          if (res.alert == "info") {
            this.closeDialogBox('update');
          }
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.customer.saveItem(this.customers).then((res) => {
          if (res.alert == "info") {
            this.closeDialogBox('save');
          }
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(profile) {
    this.submitted = false;
    this.hideDisableFields = true;

    if (profile != "") {
      const customerType = this.customerTypeFilter(
        profile.cusData.customerType
      );
      const gender    = this.genderFilter(profile.cusData.gender);
      const custClass = this.classFilter(profile.cusData.customerClass);
      this.customers.stateProvince = profile.cusData.state;
      this.customers.id = profile.cusData.customerId;
      this.customers.customerId = profile.cusData.customerId;
      this.customers.firstName = profile.cusData.firstName;
      this.customers.lastName = profile.cusData.lastName;
      this.customers.email = profile.cusData.email;
      this.customers.phone1 = profile.cusData.contact;
      this.customers.homePhone = profile.cusData.phone;
      this.customers.misc = profile.cusData.companyName;
      this.customers.customerType = customerType;
      this.customers.address = profile.cusData.address;
      this.customers.city = profile.cusData.city;
      this.customers.zipCode = profile.cusData.zipCode;
      this.customers.complex = profile.cusData.complex;
      this.customers.branchId = profile.cusData.branchId;
      this.customers.provincialRate = profile.cusData.pstExempt;
      this.customers.canadaRate = profile.cusData.hstExempt;
      this.customers.exempt1 = profile.cusData.exempt1;
      this.customers.exempt2 = profile.cusData.exempt2;
      this.customers.keyTags = profile.cusData.keyTags;
      this.customers.urgentMemo = profile.cusData.urgentMemo;
      this.customers.birthday = profile.cusData.birthday;
      this.customers.gender = gender;
      this.customers.maleNeck = profile.cusData.maleNeck;
      this.customers.maleFullChest = profile.cusData.maleFullChest;
      this.customers.maleFrontChestArea = profile.cusData.maleFrontChestArea;
      this.customers.maleBackChest = profile.cusData.maleBackChest;
      this.customers.maleFullShoulderWidth = profile.cusData.maleFullShoulder;
      this.customers.maleBlazerSleeve = profile.cusData.maleBlazerSleeve;
      this.customers.maleBicep = profile.cusData.maleBicep;
      this.customers.maleWrist = profile.cusData.maleWrist;
      this.customers.maleTrouserOutseam = profile.cusData.maleTrouserOutseam;
      this.customers.maleThigh = profile.cusData.maleThigh;
      this.customers.maleKnee = profile.cusData.maleKnee;
      this.customers.maleHipSeat = profile.cusData.maleHips;
      this.customers.maleHalfBackLength = profile.cusData.maleHalfBackLenght;
      this.customers.femaleNeck = profile.cusData.femaleNeck;
      this.customers.femaleOverBrust = profile.cusData.femaleOverBrust;
      this.customers.femaleBrust = profile.cusData.femaleBrust;
      this.customers.femaleUnderBrust = profile.cusData.femaleUnderBust;
      this.customers.femaleWaist = profile.cusData.femaleWaist;
      this.customers.femaleHips = profile.cusData.femaleHips;
      this.customers.femaleNeckHeal = profile.cusData.femaleNeckHeel;
      this.customers.femaleArmLenght = profile.cusData.femaleArmLength;
      this.customers.femaleShoulderSeam = profile.cusData.femaleShoulderSeam;
      this.customers.femaleBicep = profile.cusData.femaleBicep;
      this.customers.femaleForearm = profile.cusData.femaleForearm;
      this.customers.femaleWrist = profile.cusData.femaleWrist;
      //this.customers.customerReviews = profile.cusData.customerReviews;

      this.customers.class = custClass;
      this.customers.pointFeature = profile.cusData.pointsFeature;
      this.customers.promoNotification = profile.cusData.promotion;
      this.customers.emailNotification = profile.cusData.enableEmail;
      this.customers.textMessage = profile.cusData.textMsg;
      this.customers.pointEarned = profile.cusData.custStoreCredit;
      this.customers.ytdVisits = profile.ytdVisits;
      this.customers.ytdTotals = profile.ytdTotals;
      this.customers.totalVisits = profile.totalVisits;
      this.customers.totalService = profile.totalService;
      this.customers.totalDues = profile.totalDues;
      this.customers.lastVisit = profile.lastVisit;
      this.customers.lastDues = profile.lastDues;
      this.customers.customerSince = profile.cusData.createdDate;
      this.customers.customerServices = profile.customerServices;
      this.customers.status = profile.cusData.status;
      this.customerReviews = profile.cusData.customerReviews;
      this.productDialog = true;
    }
  }

  get customerReviewsList() {
    return this.customerReviews;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }


  //USED TO FILTER CUSTOMER TYPE
  customerTypeFilter(res) {
    let cusType = { value: "Regular" };

    this.cusType.filter((elem) => {
      if (elem.value == res) {
        cusType = elem;
      }
    });
    return cusType;
  }

  //USED TO FILTER GENDER
  genderFilter(res) {
    let gender = { value: "Female" };
    this.genderClass.filter((elem) => {
      if (elem.value == res) {
        gender = elem;
      }
    });
    return gender;
  }

  //USED TO FILTER CLASS
  classFilter(res) {
    let Cusclass = { value: "Entry Level" };
    this.customerClass.filter((elem) => {
      if (elem.value == res) {
        Cusclass = elem;
      }
    });
    return Cusclass;
  }

  clearItem() {
    this.hideDisableFields = false;
    this.customers.id = "";
    this.customers.customerId = "";
    this.customers.firstName = "";
    this.customers.lastName = "";
    this.customers.email = "";
    this.customers.phone1 = "";
    this.customers.homePhone = "";
    this.customers.misc = "";
    this.customers.customerType = { value: "Regular" },
    this.customers.address = "";
    this.customers.city = "";
    this.customers.zipCode = "";
    this.customers.complex = "";
    this.customers.stateProvince = {};
    this.customers.branchId = 0;
    this.customers.provincialRate = "no";
    this.customers.canadaRate = "no";
    this.customers.exempt1 = "";
    this.customers.exempt2 = "";
    this.customers.keyTags = "";
    this.customers.urgentMemo = "";
    this.customers.birthday = "";
    this.customers.gender = { value: "Female" };
    this.customers.maleNeck = 0;
    this.customers.maleFullChest = 0;
    this.customers.maleFrontChestArea = 0;
    this.customers.maleBackChest = 0;
    this.customers.maleFullShoulderWidth = 0;
    this.customers.maleBlazerSleeve = 0;
    this.customers.maleBicep = 0;
    this.customers.maleWrist = 0;
    this.customers.maleTrouserOutseam = 0;
    this.customers.maleThigh = 0;
    this.customers.maleKnee = 0;
    this.customers.maleHipSeat = 0;
    this.customers.maleHalfBackLength = 0;
    this.customers.femaleNeck = 0;
    this.customers.femaleOverBrust = 0;
    this.customers.femaleBrust = 0;
    this.customers.femaleUnderBrust = 0;
    this.customers.femaleWaist = 0;
    this.customers.femaleHips = 0;
    this.customers.femaleNeckHeal = 0;
    this.customers.femaleArmLenght = 0;
    this.customers.femaleShoulderSeam = 0;
    this.customers.femaleBicep = 0;
    this.customers.femaleForearm = 0;
    this.customers.femaleWrist = 0;
    this.customers.cusComments = "";
    this.customers.class = { value: "Entry Level" };
    this.customers.pointFeature = "true";
    this.customers.promoNotification = "false";
    this.customers.pointEarned = 0;
    this.customers.emailNotification = "true";
    this.customers.textMessage = "false";
    this.customers.cusCredit = 0;
    this.customers.ytdVisits = 0;
    this.customers.ytdTotals = 0;
    this.customers.totalVisits = 0;
    this.customers.totalService = 0;
    this.customers.totalDues = "";
    this.customers.lastVisit = "";
    this.customers.lastDues = 0;
    this.customers.customerSince = "";
    this.customers.customerServices = [];
  }

  closeDialogBox(action) {
    this.submitted = false;
    this.productDialog = false;
    const customers = [
      this.customers.customerId,
      this.customers.firstName + " " + this.customers.lastName,
      this.customers.email,
      this.customers.homePhone,
      action
    ];

    this.$emit("updateCustomerStatus", customers);
    this.clearItem();
  }

  // setPhoneNo(v)
  // {
  //   this.customers.phone1 = v;
  // }
}

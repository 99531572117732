
import { Options, Vue,mixins } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";
import moment from "moment";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.dueDateDialog = obj.dueDateDialog;
    },
  },
  components: {},
  emits: ["emitdueDateEvent"],
})
export default class DueDate extends mixins(UtilityOptions){
  private toast;
  private dueDateDialog = false;
  private itemDetail;
  private checkTxn;
  private dateValue = "";
  private timeValue = '';
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get minDateValue() {
    return new Date();

  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitDueDateDetail() {
    if (this.dateValue == "" || this.timeValue == '') {
      this.toast.showWarning("Please set both due Date & Time");
    } else {
      const dueDate = moment(this.dateValue).format("YYYY-MM-DD");
      this.$emit("emitdueDateEvent", [
        dueDate,
        this.timeValue,
        "setDate",
      ]);
      this.dueDateDialog = false;
    }
  }

  emitCancelDueDate() {
    this.dateValue = "";
    this.timeValue = '';

    this.$emit("emitdueDateEvent", [
      this.dateValue,
      this.timeValue,
      "closeDialog",
    ]);
    this.dueDateDialog = false;
  }

  setDueTime(dueTime) {
    this.timeValue = dueTime;
  }
}

<template>
  <Dialog
    v-model:visible="productDialog"
    :style="{ width: '100vw'}"
    :maximizable="false"
    position="top"
    :showHeader="false"
    class="p-fluid p-m-0 p-dialog-maximized"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <h5 class="p-dialog-titlebar p-dialog-titlebar-icon">
        <i class="pi pi-plus-circle"></i> {{ dialogTitle }}
      </h5>
    </template>
    <div class="p-grid">
      <div class="p-col-12">
        <TabView>
          <TabPanel header="Basic">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label for="customerID">Customer ID</label>
                  <InputText
                    id="customerID"
                    disabled="true"
                    v-model="customers.customerId"
                  />
                </div>
                <div class="p-field">
                  <label for="firstName">First Name / Company Name</label>
                  <InputText
                    id="firstName"
                    v-model.trim="customers.firstName"
                    :class="{ 'p-invalid': submitted && !customers.firstName }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !customers.firstName"
                    >First Name is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="lastName">Last Name</label>
                  <InputText id="lastName" v-model.trim="customers.lastName"
                   :class="{ 'p-invalid': submitted && !customers.lastName }"
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !customers.lastName"
                    >Last Name is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="email">Email Address</label>
                  <InputText id="email" v-model="customers.email" />
                </div>
              </div>
              <!--.p-col-6-->

              <div class="p-col-6">
                <div class="p-field">
                  <label for="phone">Mobile/Contact No <span style="color:#c00;">(MUST BE UNIQUE)</span></label>
                  <InputText
                    id="phone"
                    :useGrouping="false"
                    autoFocus
                    v-model="customers.phone1"
                    :class="{ 'p-invalid': submitted && !customers.phone1 }"
                   
                  />
                  <small
                    class="p-invalid"
                    v-if="submitted && !customers.phone1"
                    >Phone No is required.</small
                  >
                </div>
                <div class="p-field">
                  <label for="homePhone">Home Phone (Tel) </label>
                  <InputText
                    id="homePhone"
                    :useGrouping="false"
                    v-model="customers.homePhone"
                  />
                </div>
                <div class="p-field">
                  <label for="misc">Misc</label>
                  <InputText id="misc" v-model="customers.misc" />
                </div>
                <div class="p-field">
                  <label for="customerType">Customer Type</label>
                  <Dropdown
                    id="customerType"
                    v-model="customers.customerType"
                    :options="cusType"
                    optionLabel="value"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Address">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-field">
                  <label for="address">Address</label>
                  <InputText id="address" v-model="customers.address" />
                </div>
              </div>
              <!--.p-col-12-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="city">City</label>
                  <InputText id="city" v-model="customers.city" />
                </div>
                <div class="p-field">
                  <label for="zipcode">Zip/Post Code</label>
                  <InputText id="zipcode" v-model="customers.zipCode" />
                </div>
                <div class="p-field">
                  <label for="complex">Complex</label>
                  <InputText id="complex" v-model="customers.complex" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label for="stateProvince">Province</label>
                  <Dropdown
                    id="stateProvince"
                    v-model="customers.stateProvince"
                    :options="provinceList"
                    optionLabel="key"
                    optionValue="key"
                    :filter="true"
                  />
                </div>
                <div class="p-field">
                  <label for="branchId">Branch ID</label>
                  <InputText
                    id="branchId"
                    v-model="customers.branchId"
                    disabled="true"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Other Details">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <div class="form-check">
                    <input
                      style="width: 30px; height: 30px"
                      class="form-check-input"
                      type="checkbox"
                      v-model="customers.provincialRate"
                      true-value="yes"
                      false-value="no"
                    />
                    <label class="form-check-label ml-3" for="provincialRate">
                      Provincial Rate (Exempt)
                    </label>
                  </div>
                </div>

                <div class="p-field">
                  <label for="exempt1">Exempt #</label>
                  <InputText id="exempt1" v-model="customers.exempt1" />
                </div>
                <div class="p-field">
                  <label for="keyTags">MISC</label>
                  <InputText id="keyTags" v-model="customers.keyTags" />
                </div>
                <div class="p-field">
                  <label for="urgentMemo">Urgent Memo</label>
                  <InputText id="urgentMemo" v-model="customers.urgentMemo" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      style="width: 30px; height: 30px"
                      type="checkbox"
                      v-model="customers.canadaRate"
                      true-value="yes"
                      false-value="no"
                    />
                    <label class="form-check-label ml-3" for="canadaRate">
                      Federal Rate (Exempt)
                    </label>
                  </div>
                </div>
                <div class="p-field">
                  <label for="exempt2">Exempt #</label>
                  <InputText id="exempt2" v-model="customers.exempt2" />
                </div>
                <div class="p-field">
                  <label for="birthday">Birthday</label>
                  <input
                    type="date"
                    class="form-control"
                    v-model="customers.birthday"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Measurements">
            <div class="p-grid">
              <div class="p-col-12">
                <label for="gender">Gender</label>
                <Dropdown
                  id="gender"
                  v-model="customers.gender"
                  :options="genderClass"
                  optionLabel="value"
                />
              </div>

              <div class="p-col-6" v-if="customers.gender.value == 'Male'">
                <div class="p-field">
                  <label for="neck">Neck</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="neck"
                    v-model="customers.maleNeck"
                  />
                </div>
                <div class="p-field">
                  <label for="fullChest">Full Chest</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="fullChest"
                    v-model="customers.maleFullChest"
                  />
                </div>
                <div class="p-field">
                  <label>Front Chest Area</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.maleFrontChestArea"
                  />
                </div>
                <div class="p-field">
                  <label>Back Chest</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.maleBackChest"
                  />
                </div>
                <div class="p-field">
                  <label for="fullShoulderWidth">Full Shoulder Width</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="fullShoulderWidth"
                    v-model="customers.maleFullShoulderWidth"
                  />
                </div>
                <div class="p-field">
                  <label for="blazerSleeve">Blazer Sleeve</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="blazerSleeve"
                    v-model="customers.maleBlazerSleeve"
                  />
                </div>
                <div class="p-field">
                  <label for="bicep">Bicep</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="bicep"
                    v-model="customers.maleBicep"
                  />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6" v-if="customers.gender.value == 'Male'">
                <div class="p-field">
                  <label for="wrist">Wrist</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="wrist"
                    v-model="customers.maleWrist"
                  />
                </div>
                <div class="p-field">
                  <label for="trouserOutseam">Trouser Outseam</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="trouserOutseam"
                    v-model="customers.maleTrouserOutseam"
                  />
                </div>
                <div class="p-field">
                  <label for="thigh">Thigh</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="thigh"
                    v-model="customers.maleThigh"
                  />
                </div>
                <div class="p-field">
                  <label for="knee">Knee</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="knee"
                    v-model="customers.maleKnee"
                  />
                </div>
                <div class="p-field">
                  <label for="hipsSeat">Hips/Seat</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="hipsSeat"
                    v-model="customers.maleHipSeat"
                  />
                </div>
                <div class="p-field">
                  <label>Half Back Length</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.maleHalfBackLength"
                  />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6" v-if="customers.gender.value == 'Female'">
                <div class="p-field">
                  <label for="neck">Neck</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="neck"
                    v-model="customers.femaleNeck"
                  />
                </div>
                <div class="p-field">
                  <label for="fullChest">Over Brust</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="fullChest"
                    v-model="customers.femaleOverBrust"
                  />
                </div>
                <div class="p-field">
                  <label>Brust</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.femaleBrust"
                  />
                </div>
                <div class="p-field">
                  <label>Under Bust</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.femaleUnderBrust"
                  />
                </div>
                <div class="p-field">
                  <label for="fullShoulderWidth">Waist</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="fullShoulderWidth"
                    v-model="customers.femaleWaist"
                  />
                </div>
                <div class="p-field">
                  <label for="blazerSleeve">Hips</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="blazerSleeve"
                    v-model="customers.femaleHips"
                  />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6" v-if="customers.gender.value == 'Female'">
                <div class="p-field">
                  <label for="wrist">Neck Heel</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="wrist"
                    v-model="customers.femaleNeckHeal"
                  />
                </div>
                <div class="p-field">
                  <label for="trouserOutseam">Arm Length</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="trouserOutseam"
                    v-model="customers.femaleArmLenght"
                  />
                </div>
                <div class="p-field">
                  <label for="thigh">Shoulder Seam</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="thigh"
                    v-model="customers.femaleShoulderSeam"
                  />
                </div>
                <div class="p-field">
                  <label for="knee">Bicep</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="knee"
                    v-model="customers.femaleBicep"
                  />
                </div>
                <div class="p-field">
                  <label for="hipsSeat">Forearm</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    id="hipsSeat"
                    v-model="customers.femaleForearm"
                  />
                </div>
                <div class="p-field">
                  <label>Wrist</label>
                  <InputNumber
                    :useGrouping="false"
                    mode="decimal"
                    :minFractionDigits="1"
                    v-model="customers.femaleWrist"
                  />
                </div>
              </div>
              <!--.p-col-6-->
            </div>
          </TabPanel>
          <TabPanel header="Note">
            <div class="p-grid">
              <div class="p-col-12">
                <div class="p-field-radiobutton">
                  <span class="badge badge-success px-3 py-2 mx-2"
                    ><RadioButton
                      id="city1"
                      name="city"
                      value="green"
                      v-model="customers.cusMood"
                  /></span>
                  <span class="badge badge-danger px-3 py-2 mx-2"
                    ><RadioButton
                      id="city2"
                      name="city"
                      value="red"
                      v-model="customers.cusMood"
                  /></span>
                </div>
              </div>
              <!--.p-col-12-->
              <div class="p-col-12">
                <div class="p-field">
                  <div class="form-group">
                    <label for="comment">Comments</label>
                    <textarea
                      class="form-control"
                      v-model="customers.cusComments"
                      id="comment"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
                <div class="p-grid" v-if="hideDisableFields == true">
                  <div class="p-col"></div>
                  <div class="p-col-fixed" style="width: 190px">
                    <Button
                      label="Add Reviews"
                      @click="addReview"
                      icon="pi pi-plus-circle"
                      iconPos="left"
                    />
                  </div>
                </div>
              </div>
              <!--.p-col-12-->
              <div class="p-col-12" v-if="hideDisableFields == true">
                <DataTable
                  :value="customerReviewsList"
                  class="p-datatable-striped p-datatable-gridlines p-datatable-sm"
                >
                  <Column header="Emoji">
                    <template #body="slotProps">
                      <span v-if="slotProps.data.customerMood != null">{{
                        slotProps.data.customerMood.toUpperCase()
                      }}</span>
                    </template>
                  </Column>
                  <Column field="note" header="Customer reviews">
                    <template #body="slotProps">
                      {{ slotProps.data.note }}
                    </template>
                  </Column>
                  <Column field="visitDate" header="Date">
                    <template #body="slotProps">
                      {{ formatDate(slotProps.data.visitDate) }}
                    </template>
                  </Column>
                  <Column field="visitTime" header="Time">
                    <template #body="slotProps">
                      {{ formatTime(slotProps.data.visitTime) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
              <!--.p-col-12-->
            </div>
          </TabPanel>
          <TabPanel header="History" :disabled="!hideDisableFields">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="p-field">
                  <label>YTD Visits</label>
                  <InputNumber
                    :useGrouping="false"
                    disabled="true"
                    v-model="customers.ytdVisits"
                  />
                </div>
                <div class="p-field">
                  <label>YTD Services</label>
                  <div class="p-inputgroup">
                    <InputNumber
                      :useGrouping="false"
                      class="mr-2"
                      disabled="true"
                      :value="formatAmount(customers.ytdTotals)"
                    />
                  </div>
                </div>
                <div class="p-field">
                  <label>Customer Since</label>
                  <InputText
                    disabled="true"
                    v-model="customers.customerSince"
                    :value="formatDate(customers.customerSince)"
                  />
                </div>
                <div class="p-field">
                  <label>Total Due</label>
                  <InputText disabled="true" :value="formatAmount(customers.totalDues)" />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <label>Total Visits</label>
                  <InputNumber
                    :useGrouping="false"
                    disabled="true"
                    v-model="customers.totalVisits"
                  />
                </div>
                <div class="p-field">
                  <label>Total Services</label>
                  <div class="p-inputgroup">
                    <InputNumber
                      :useGrouping="false"
                      class="mr-2"
                      disabled="true"
                      :value="formatAmount(customers.totalService)"
                    />
                    <InputNumber
                      :useGrouping="false"
                      disabled="true"
                      v-model="customers.totalVisits"
                    />
                  </div>
                </div>
                <div class="p-field">
                  <label>Last Visits</label>
                  <InputText disabled="true" v-model="customers.lastVisit" />
                </div>
                <div class="p-field">
                  <label>Over Due( > 30 days)</label>
                  <InputNumber
                    :useGrouping="false"
                    disabled="true"
                    :value="formatAmount(customers.lastDues)"
                  />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-12">
                <DataTable
                  :value="customers.customerServices"
                  class="p-datatable-striped p-datatable-gridlines p-datatable-sm"
                >
                  <Column field="storeName" header="Store Name"></Column>
                  <Column field="name" header="Services"></Column>
                  <Column field="date" header="Last visit Date">
									<template #body="slotProps">
											{{formatDate(slotProps.data.date)}}
									</template>
									</Column>
                  <Column field="qty" header="Qty"></Column>
                  <Column header="Total">
                    <template #body="slotProps">
                      ${{ formatAmount(slotProps.data.totalAmount) }}
                    </template>
                  </Column>
                </DataTable>
              </div>
              <!--.p-col-12-->
            </div>
          </TabPanel>
          <TabPanel header="Marketing">
            <div class="p-grid">
              <div class="p-col-12">
                <label for="class">Class</label>
                <Dropdown
                  id="class"
                  v-model="customers.class"
                  :options="customerClass"
                  optionLabel="value"
                />
              </div>
              <div class="p-col-6">
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="enablePoints">Enable Points Feature</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="promoNotification"
                    >Enable This Customer to Receive Promotion
                    Notification</label
                  >
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="pointsearned">Points Earned ($)</label>
                </div>
                <div class="p-field my-3" v-if="hideDisableFields == true">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="earned">Add Needle Points ($)</label>
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="emailNotification"
                    >Enable This Customer to Receive Ready Invoice Email
                    Notification</label
                  >
                </div>
                <div class="p-field my-3">
                  <i class="pi pi-arrow-circle-right mr-2"></i>
                  <label for="textMessage"
                    >Enable This Customer to Receive Ready Invoice Text
                    Message(SMS)</label
                  >
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-6">
                <div class="p-field">
                  <input
                    style="width: 30px; height: 30px"
                    v-model="customers.pointFeature"
                    type="checkbox"
                    id="enablePoints"
                  />
                </div>
                <div class="p-field">
                  <input
                    style="width: 30px; height: 30px"
                    v-model="customers.promoNotification"
                    type="checkbox"
                    id="promoNotification"
                  />
                </div>
                <div class="p-field">
                  <InputText
                    class="w-25"
                    disabled="true"
                    id="pointsearned"
                    v-model="customers.pointEarned"
                  />
                </div>
                <div class="p-field" v-if="hideDisableFields == true">
                  <InputNumber
                    :useGrouping="false"
                    id="storeCredit"
                    class="w-25 mr-2"
                    mode="currency"
                    v-model="customers.cusCredit"
                    currency="USD"
                    locale="en-US"
                  />
                  <Button
                    label="Add"
                    style="width: 6rem"
                    icon="pi pi-plus-circle"
                    @click="addCustomerCredit"
                    iconPos="left"
                  />
                </div>
                <div class="p-field">
                  <input
                    style="width: 30px; height: 30px"
                    v-model="customers.emailNotification"
                    type="checkbox"
                    id="emailNotification"
                  />
                </div>
                <div class="p-field">
                  <input
                    style="width: 30px; height: 30px"
                    v-model="customers.textMessage"
                    type="checkbox"
                    id="textMessage"
                  />
                </div>
              </div>
              <!--.p-col-6-->
              <div class="p-col-12">
                <hr />
                <div class="p-d-flex p-jc-end">
                  <div class="p-mr-2">
                    <Button
                      v-if="permission == 'yes'"
                      label="Save"
                      icon="pi pi-check"
                      class="p-button-success pull-left"
                      @click="saveItem"
                    />
                  </div>
                  <div class="p-mr-2">
                    <Button
                      label="Close"
                      icon="pi pi-times"
                      class="p-button-danger pull-left"
                      @click="closeDialogBox('close')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Toaster from "../helpers/Toaster";
import Customer from "../service/Customer";
import { RegionList, CustomerReviews } from "../pages/customers/ICustomers";
import moment from "moment";
import { useStore, ActionTypes } from "../store";

@Options({
  props: {
    customerDetail: Object,
  },
  watch: {
    customerDetail(obj) {
      if (obj.status == "openCustomer") {
        this.customers.customerId = obj.customerId;
        this.customers.branchId = obj.customerStoreID;
        this.customers.phone1 = this.selectedKeyword;
        this.openDialog();
      } else if (obj.status == "updateCustomer") {
        this.editIemDialog(obj.customerProfile);
      } else {
        this.dialogTitle = "";
      }
     
      this.permission = obj.permission;
      this.dialogTitle = obj.title;
    },
  },
  emits: ["updateCustomerStatus"],
})
export default class CustomerDialog extends Vue {
  private toast;
  private submitted = false;
  private productDialog = false;
  private dialogTitle = "";
  private permission = "";
  private store = useStore();
  private customer;
  private managerList = [];
  private storeList = [];
  private customerReviews: CustomerReviews[] = [];
  private provinceList  = [
    { key: "Nunavut" },
    { key: "Quebec" },
    { key: "Northwest Territories" },
    { key: "Ontario" },
    { key: "British Columbia" },
    { key: "Alberta" },
    { key: "Saskatchewan" },
    { key: "Yukon" },
    { key: "Newfoundland and Labrado" },
    { key: "New Brunswick" },
    { key: "Nova Scotia" },
    { key: "Alabama" },
    { key: "Alaska" },
    { key: "Arizona" },
    { key: "Arkansas" },
    { key: "California" },
    { key: "Colorado" },
    { key: "Connecticut" },
    { key: "Delaware" },
    { key: "Florida" },
    { key: "Georgia" },
    { key: "Hawaii" },
    { key: "Idaho" },
    { key: "Illinois" },
    { key: "Indiana" },
    { key: "Iowa" },
    { key: "Kansas" },
    { key: "Kentucky" },
    { key: "Louisiana" },
    { key: "Maine" },
    { key: "Maryland" },
    { key: "Massachusetts" },
    { key: "Michigan" },
    { key: "Minnesota" },
    { key: "Mississippi" },
    { key: "Missouri" },
    { key: "Montana" },
    { key: "Nebraska" },
    { key: "Nevada" },
    { key: "New Hampshire" },
    { key: "New Jersey" },
    { key: "New Mexic" },
    { key: "New York" },
    { key: "North Carolina" },
    { key: "North Dakota" },
    { key: "Ohio" },
    { key: "Oklahoma" },
    { key: "Oregon" },
    { key: "Pennsylvania" },
    { key: "Rhode Island" },
    { key: "South Carolina" },
    { key: "South Dakota" },
    { key: "Tennessee" },
    { key: "Texas" },
    { key: "Utah" },
    { key: "Vermont" },
    { key: "Virginia" },
    { key: "Washington" },
    { key: "West Virginia" },
    { key: "Wisconsin" },
    { key: "Wyoming" },
  ];

  //private customerProfile = {};
  private hideDisableFields = false;

  private customerClass = [{ value: "Entry Level" }];

  private genderClass = [{ value: "Male" }, { value: "Female" }];

  private cusType = [
		{ value: "Regular" }
		];

  private customers = {
    id: "",
    customerId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone1: this.selectedKeyword,
    homePhone: "",
    misc: "",
    customerType: { value: "Regular" },
    address: "",
    city: "",
    zipCode: "",
    complex: "",
    stateProvince: {},
    branchId: 0,
    provincialRate: "no",
    canadaRate: "no",
    exempt1: "",
    exempt2: "",
    keyTags: "",
    urgentMemo: "",
    birthday: "",
    gender: { value: "Female" },
    maleNeck: 0,
    maleFullChest: 0,
    maleFrontChestArea: 0,
    maleBackChest: 0,
    maleFullShoulderWidth: 0,
    maleBlazerSleeve: 0,
    maleBicep: 0,
    maleWrist: 0,
    maleTrouserOutseam: 0,
    maleThigh: 0,
    maleKnee: 0,
    maleHipSeat: 0,
    maleHalfBackLength: 0,
    femaleNeck: 0,
    femaleOverBrust: 0,
    femaleBrust: 0,
    femaleUnderBrust: 0,
    femaleWaist: 0,
    femaleHips: 0,
    femaleNeckHeal: 0,
    femaleArmLenght: 0,
    femaleShoulderSeam: 0,
    femaleBicep: 0,
    femaleForearm: 0,
    femaleWrist: 0,
    cusMood: "green",
    cusComments: "",
    customerReviews: this.customerReviewsList,
    class: { value: "Entry Level" },
    pointFeature: "true",
    promoNotification: "false",
    pointEarned: 0,
    emailNotification: "true",
    textMessage: "false",
    cusCredit: 0,
    ytdVisits: 0,
    ytdTotals: 0,
    totalVisits: 0,
    totalService: 0,
    totalDues: "",
    lastVisit: "",
    lastDues: 0,
    customerSince: "",
    customerServices: [],
    status: "Active",
  };
  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  get selectedKeyword() {
    return this.store.getters.getReceiptID;
  }

  formatAmount(value) {

    value = Number(value);

    let amount = 0;

    if (value != "") {
      amount = value.toFixed(2);
    }

    return Number(amount);
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.submitted = false;
    this.productDialog = true;
  }

  //ADD REVIEWS
  addReview() {
    if (this.customers.cusComments == "") {
      this.toast.showWarning("Empty Comment!");
    } else {
      const review: CustomerReviews = {
        customerMood: this.customers.cusMood,
        note: this.customers.cusComments,
        visitDate: moment().format("YYYY-MM-DD"),
        visitTime: moment().format("HH:mm")
      };

      this.customerReviews.push(review);
      this.customers.cusComments = "";
      this.toast.showSuccess("Review added to apply changes please click save");
    }
  }

  //ADD CUSTOMER CREDIT
  addCustomerCredit() {
    if (this.customers.cusCredit == 0) {
      this.toast.showWarning("Please enter amount");
    } else {
      this.customers.pointEarned =
        Number(this.customers.pointEarned) + this.customers.cusCredit;
      this.customers.cusCredit = 0;
      this.toast.showSuccess("Store Credit added to apply changes please click save");
    }
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.customers.firstName.trim() && this.customers.lastName.trim() && this.customers.phone1.trim()) {
      if (this.customers.id != "") {
        this.customer.updateItem(this.customers,this.customerReviewsList).then((res) => {
          if (res.alert == "info") {
            this.closeDialogBox('update');
          }
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.customer.saveItem(this.customers).then((res) => {
          if (res.alert == "info") {
            this.closeDialogBox('save');
          }
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIemDialog(profile) {
    this.submitted = false;
    this.hideDisableFields = true;

    if (profile != "") {
      const customerType = this.customerTypeFilter(
        profile.cusData.customerType
      );
      const gender    = this.genderFilter(profile.cusData.gender);
      const custClass = this.classFilter(profile.cusData.customerClass);
      this.customers.stateProvince = profile.cusData.state;
      this.customers.id = profile.cusData.customerId;
      this.customers.customerId = profile.cusData.customerId;
      this.customers.firstName = profile.cusData.firstName;
      this.customers.lastName = profile.cusData.lastName;
      this.customers.email = profile.cusData.email;
      this.customers.phone1 = profile.cusData.contact;
      this.customers.homePhone = profile.cusData.phone;
      this.customers.misc = profile.cusData.companyName;
      this.customers.customerType = customerType;
      this.customers.address = profile.cusData.address;
      this.customers.city = profile.cusData.city;
      this.customers.zipCode = profile.cusData.zipCode;
      this.customers.complex = profile.cusData.complex;
      this.customers.branchId = profile.cusData.branchId;
      this.customers.provincialRate = profile.cusData.pstExempt;
      this.customers.canadaRate = profile.cusData.hstExempt;
      this.customers.exempt1 = profile.cusData.exempt1;
      this.customers.exempt2 = profile.cusData.exempt2;
      this.customers.keyTags = profile.cusData.keyTags;
      this.customers.urgentMemo = profile.cusData.urgentMemo;
      this.customers.birthday = profile.cusData.birthday;
      this.customers.gender = gender;
      this.customers.maleNeck = profile.cusData.maleNeck;
      this.customers.maleFullChest = profile.cusData.maleFullChest;
      this.customers.maleFrontChestArea = profile.cusData.maleFrontChestArea;
      this.customers.maleBackChest = profile.cusData.maleBackChest;
      this.customers.maleFullShoulderWidth = profile.cusData.maleFullShoulder;
      this.customers.maleBlazerSleeve = profile.cusData.maleBlazerSleeve;
      this.customers.maleBicep = profile.cusData.maleBicep;
      this.customers.maleWrist = profile.cusData.maleWrist;
      this.customers.maleTrouserOutseam = profile.cusData.maleTrouserOutseam;
      this.customers.maleThigh = profile.cusData.maleThigh;
      this.customers.maleKnee = profile.cusData.maleKnee;
      this.customers.maleHipSeat = profile.cusData.maleHips;
      this.customers.maleHalfBackLength = profile.cusData.maleHalfBackLenght;
      this.customers.femaleNeck = profile.cusData.femaleNeck;
      this.customers.femaleOverBrust = profile.cusData.femaleOverBrust;
      this.customers.femaleBrust = profile.cusData.femaleBrust;
      this.customers.femaleUnderBrust = profile.cusData.femaleUnderBust;
      this.customers.femaleWaist = profile.cusData.femaleWaist;
      this.customers.femaleHips = profile.cusData.femaleHips;
      this.customers.femaleNeckHeal = profile.cusData.femaleNeckHeel;
      this.customers.femaleArmLenght = profile.cusData.femaleArmLength;
      this.customers.femaleShoulderSeam = profile.cusData.femaleShoulderSeam;
      this.customers.femaleBicep = profile.cusData.femaleBicep;
      this.customers.femaleForearm = profile.cusData.femaleForearm;
      this.customers.femaleWrist = profile.cusData.femaleWrist;
      //this.customers.customerReviews = profile.cusData.customerReviews;

      this.customers.class = custClass;
      this.customers.pointFeature = profile.cusData.pointsFeature;
      this.customers.promoNotification = profile.cusData.promotion;
      this.customers.emailNotification = profile.cusData.enableEmail;
      this.customers.textMessage = profile.cusData.textMsg;
      this.customers.pointEarned = profile.cusData.custStoreCredit;
      this.customers.ytdVisits = profile.ytdVisits;
      this.customers.ytdTotals = profile.ytdTotals;
      this.customers.totalVisits = profile.totalVisits;
      this.customers.totalService = profile.totalService;
      this.customers.totalDues = profile.totalDues;
      this.customers.lastVisit = profile.lastVisit;
      this.customers.lastDues = profile.lastDues;
      this.customers.customerSince = profile.cusData.createdDate;
      this.customers.customerServices = profile.customerServices;
      this.customers.status = profile.cusData.status;
      this.customerReviews = profile.cusData.customerReviews;
      this.productDialog = true;
    }
  }

  get customerReviewsList() {
    return this.customerReviews;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm:ss").format("hh:mm A");
    }
  }


  //USED TO FILTER CUSTOMER TYPE
  customerTypeFilter(res) {
    let cusType = { value: "Regular" };

    this.cusType.filter((elem) => {
      if (elem.value == res) {
        cusType = elem;
      }
    });
    return cusType;
  }

  //USED TO FILTER GENDER
  genderFilter(res) {
    let gender = { value: "Female" };
    this.genderClass.filter((elem) => {
      if (elem.value == res) {
        gender = elem;
      }
    });
    return gender;
  }

  //USED TO FILTER CLASS
  classFilter(res) {
    let Cusclass = { value: "Entry Level" };
    this.customerClass.filter((elem) => {
      if (elem.value == res) {
        Cusclass = elem;
      }
    });
    return Cusclass;
  }

  clearItem() {
    this.hideDisableFields = false;
    this.customers.id = "";
    this.customers.customerId = "";
    this.customers.firstName = "";
    this.customers.lastName = "";
    this.customers.email = "";
    this.customers.phone1 = "";
    this.customers.homePhone = "";
    this.customers.misc = "";
    this.customers.customerType = { value: "Regular" },
    this.customers.address = "";
    this.customers.city = "";
    this.customers.zipCode = "";
    this.customers.complex = "";
    this.customers.stateProvince = {};
    this.customers.branchId = 0;
    this.customers.provincialRate = "no";
    this.customers.canadaRate = "no";
    this.customers.exempt1 = "";
    this.customers.exempt2 = "";
    this.customers.keyTags = "";
    this.customers.urgentMemo = "";
    this.customers.birthday = "";
    this.customers.gender = { value: "Female" };
    this.customers.maleNeck = 0;
    this.customers.maleFullChest = 0;
    this.customers.maleFrontChestArea = 0;
    this.customers.maleBackChest = 0;
    this.customers.maleFullShoulderWidth = 0;
    this.customers.maleBlazerSleeve = 0;
    this.customers.maleBicep = 0;
    this.customers.maleWrist = 0;
    this.customers.maleTrouserOutseam = 0;
    this.customers.maleThigh = 0;
    this.customers.maleKnee = 0;
    this.customers.maleHipSeat = 0;
    this.customers.maleHalfBackLength = 0;
    this.customers.femaleNeck = 0;
    this.customers.femaleOverBrust = 0;
    this.customers.femaleBrust = 0;
    this.customers.femaleUnderBrust = 0;
    this.customers.femaleWaist = 0;
    this.customers.femaleHips = 0;
    this.customers.femaleNeckHeal = 0;
    this.customers.femaleArmLenght = 0;
    this.customers.femaleShoulderSeam = 0;
    this.customers.femaleBicep = 0;
    this.customers.femaleForearm = 0;
    this.customers.femaleWrist = 0;
    this.customers.cusComments = "";
    this.customers.class = { value: "Entry Level" };
    this.customers.pointFeature = "true";
    this.customers.promoNotification = "false";
    this.customers.pointEarned = 0;
    this.customers.emailNotification = "true";
    this.customers.textMessage = "false";
    this.customers.cusCredit = 0;
    this.customers.ytdVisits = 0;
    this.customers.ytdTotals = 0;
    this.customers.totalVisits = 0;
    this.customers.totalService = 0;
    this.customers.totalDues = "";
    this.customers.lastVisit = "";
    this.customers.lastDues = 0;
    this.customers.customerSince = "";
    this.customers.customerServices = [];
  }

  closeDialogBox(action) {
    this.submitted = false;
    this.productDialog = false;
    const customers = [
      this.customers.customerId,
      this.customers.firstName + " " + this.customers.lastName,
      this.customers.email,
      this.customers.homePhone,
      action
    ];

    this.$emit("updateCustomerStatus", customers);
    this.clearItem();
  }

  // setPhoneNo(v)
  // {
  //   this.customers.phone1 = v;
  // }
}
</script>
<style scoped>
/* .p-dialog-content
{
  overflow-y: visible !important;
} */
</style>

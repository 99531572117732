<template>
  <Dialog
    v-model:visible="dueDateDialog"
    :style="{ width: '50vw' }"
    :maximizable="false"
    :closable="false"
    position="top"
    :modal="true"
    class="p-fluid  p-m-0 p-dialog-maximized"
    :showHeader="false"
  >
    <div class="p-d-flex p-jc-end">
      <div class="p-mr-2">
        <Button
          class="p-button-primary"
          icon="pi pi-check-circle"
          label="Done"
          @click="emitDueDateDetail()"
        />
      </div>
      <div class="">
        <Button
          class="p-button-danger"
          icon="pi pi-times"
          label="Cancel"
          @click="emitCancelDueDate()"
        />
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <h5 class="p-mt-3">
          <i class="pi pi-calendar"></i>
          Set Order Due Date & Time <span style="background-color:yellow">{{ formatDate(dateValue) }} </span>
        </h5>
        <div class="p-grid">
          <div class="p-col-10">
             <Calendar v-model="dateValue" :minDate="minDateValue" :inline="true"  :manualInput="false" dateFormat="yy-mm-dd" />
          </div>
          <div class="p-col-2">
            <h5 class="date-time-clock-hrs-heading">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              Available Hours
            </h5>
            <div class="clock-hours-timming">
              <h5 @click="setDueTime('09:00')" class="date-time-clock-hrs">
                09:00 AM
              </h5>
              <h5 @click="setDueTime('09:15')" class="date-time-clock-hrs">
                09:15 AM
              </h5>
              <h5 @click="setDueTime('09:30')" class="date-time-clock-hrs">
                09:30 AM
              </h5>
              <h5 @click="setDueTime('09:45')" class="date-time-clock-hrs">
                09:45 AM
              </h5>
              <h5 @click="setDueTime('10:00')" class="date-time-clock-hrs">
                10:00 AM
              </h5>
              <h5 @click="setDueTime('10:15')" class="date-time-clock-hrs">
                10:15 AM
              </h5>
              <h5 @click="setDueTime('10:30')" class="date-time-clock-hrs">
                10:30 AM
              </h5>
              <h5 @click="setDueTime('10:45')" class="date-time-clock-hrs">
                10:45 AM
              </h5>
              <h5 @click="setDueTime('11:00')" class="date-time-clock-hrs">
                11:00 AM
              </h5>
              <h5 @click="setDueTime('11:15')" class="date-time-clock-hrs">
                11:15 AM
              </h5>
              <h5 @click="setDueTime('11:30')" class="date-time-clock-hrs">
                11:30 AM
              </h5>
              <h5 @click="setDueTime('11:45')" class="date-time-clock-hrs">
                11:45 AM
              </h5>
              <h5 @click="setDueTime('12:00')" class="date-time-clock-hrs">
                12:00 PM
              </h5>
              <h5 @click="setDueTime('12:15')" class="date-time-clock-hrs">
                12:15 PM
              </h5>
              <h5 @click="setDueTime('12:30')" class="date-time-clock-hrs">
                12:30 PM
              </h5>
              <h5 @click="setDueTime('12:45')" class="date-time-clock-hrs">
                12:45 PM
              </h5>
              <h5 @click="setDueTime('13:00')" class="date-time-clock-hrs">
                01:00 PM
              </h5>
              <h5 @click="setDueTime('13:15')" class="date-time-clock-hrs">
                01:15 PM
              </h5>
              <h5 @click="setDueTime('13:30')" class="date-time-clock-hrs">
                01:30 PM
              </h5>
              <h5 @click="setDueTime('13:45')" class="date-time-clock-hrs">
                01:45 PM
              </h5>
              <h5 @click="setDueTime('14:00')" class="date-time-clock-hrs">
                02:00 PM
              </h5>
              <h5 @click="setDueTime('14:15')" class="date-time-clock-hrs">
                02:15 PM
              </h5>
              <h5 @click="setDueTime('14:30')" class="date-time-clock-hrs">
                02:30 PM
              </h5>
              <h5 @click="setDueTime('14:45')" class="date-time-clock-hrs">
                02:45 PM
              </h5>
              <h5 @click="setDueTime('15:00')" class="date-time-clock-hrs">
                03:00 PM
              </h5>
               <h5 @click="setDueTime('15:15')" class="date-time-clock-hrs">
                03:15 PM
              </h5>
               <h5 @click="setDueTime('15:30')" class="date-time-clock-hrs">
                03:30 PM
              </h5>
               <h5 @click="setDueTime('15:45')" class="date-time-clock-hrs">
                03:45 PM
              </h5>
              <h5 @click="setDueTime('16:00')" class="date-time-clock-hrs">
                04:00 PM
              </h5>
               <h5 @click="setDueTime('16:15')" class="date-time-clock-hrs">
                04:15 PM
              </h5>
               <h5 @click="setDueTime('16:30')" class="date-time-clock-hrs">
                04:30 PM
              </h5>
               <h5 @click="setDueTime('16:45')" class="date-time-clock-hrs">
                04:45 PM
              </h5>
              <h5 @click="setDueTime('17:00')" class="date-time-clock-hrs">
                05:00 PM
              </h5>
              <h5 @click="setDueTime('17:15')" class="date-time-clock-hrs">
                05:15 PM
              </h5>
              <h5 @click="setDueTime('17:30')" class="date-time-clock-hrs">
                05:30 PM
              </h5>
              <h5 @click="setDueTime('17:45')" class="date-time-clock-hrs">
                05:45 PM
              </h5>
              <h5 @click="setDueTime('18:00')" class="date-time-clock-hrs">
                06:00 PM
              </h5>
              <h5 @click="setDueTime('18:15')" class="date-time-clock-hrs">
                06:15 PM
              </h5>
              <h5 @click="setDueTime('18:30')" class="date-time-clock-hrs">
                06:30 PM
              </h5>
              <h5 @click="setDueTime('18:45')" class="date-time-clock-hrs">
                06:45 PM
              </h5>
              <h5 @click="setDueTime('19:00')" class="date-time-clock-hrs">
                07:00 PM
              </h5>
               <h5 @click="setDueTime('19:15')" class="date-time-clock-hrs">
                07:15 PM
              </h5>
               <h5 @click="setDueTime('19:30')" class="date-time-clock-hrs">
                07:30 PM
              </h5>
               <h5 @click="setDueTime('19:45')" class="date-time-clock-hrs">
                07:45 PM
              </h5>
              <h5 @click="setDueTime('20:00')" class="date-time-clock-hrs">
                08:00 PM
              </h5>
               <h5 @click="setDueTime('20:15')" class="date-time-clock-hrs">
                08:15 PM
              </h5>
               <h5 @click="setDueTime('20:30')" class="date-time-clock-hrs">
                08:30 PM
              </h5>
               <h5 @click="setDueTime('20:45')" class="date-time-clock-hrs">
                08:45 PM
              </h5>
              <h5 @click="setDueTime('21:00')" class="date-time-clock-hrs">
                09:00 PM
              </h5>
            </div>
            <input
              type="time"
              name="get_the_time"
              id="get_the_time"
              class="set_the_time_value form-control"
              v-model="timeValue"
            />
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Options, Vue,mixins } from "vue-class-component";
import CheckinService from "../service/CheckinService";
import { camelCase } from "lodash";
import { useStore } from "../store";
import Toaster from "../helpers/Toaster";
import UtilityOptions from "../mixins/UtilityOptions";
import moment from "moment";

@Options({
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.dueDateDialog = obj.dueDateDialog;
    },
  },
  components: {},
  emits: ["emitdueDateEvent"],
})
export default class DueDate extends mixins(UtilityOptions){
  private toast;
  private dueDateDialog = false;
  private itemDetail;
  private checkTxn;
  private dateValue = "";
  private timeValue = '';
  private express = false;
  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.toast = new Toaster();
  }

  get minDateValue() {
    return new Date();

  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  emitDueDateDetail() {
    if (this.dateValue == "" || this.timeValue == '') {
      this.toast.showWarning("Please set both due Date & Time");
    } else {
      const dueDate = moment(this.dateValue).format("YYYY-MM-DD");
      this.$emit("emitdueDateEvent", [
        dueDate,
        this.timeValue,
        "setDate",
      ]);
      this.dueDateDialog = false;
    }
  }

  emitCancelDueDate() {
    this.dateValue = "";
    this.timeValue = '';

    this.$emit("emitdueDateEvent", [
      this.dateValue,
      this.timeValue,
      "closeDialog",
    ]);
    this.dueDateDialog = false;
  }

  setDueTime(dueTime) {
    this.timeValue = dueTime;
  }
}
</script>
<template>
  <div class="checkoutReceiptHeight">
      <h6 class="invoice-top p-m-0">
        <i class="pi pi-file"></i>
        Invoice # {{ receiptAttr.id }}
        <span class="pull-right">
          <i class="pi pi-bars"></i> {{ receiptAttr.scheduleName }}
        </span>
      </h6>
      <p class="p-p-1 p-m-0">
        Customer Name : {{ receiptAttr.customer }}
        <span class="pull-right"> Phone : {{ receiptAttr.customerTel }} </span>
      </p>
      <p class="p-p-1 p-m-0">
        Customer Type :
          {{ receiptAttr.referalOrCorp.toUpperCase() }}
          <b
            style="color: #c00"
            v-if="receiptAttr.referalOrCorp == 'corporate'"
          >
            ( {{ receiptAttr.accountType}} )
          </b>
      </p>
      <p class="p-p-1 p-m-0" v-if="receiptAttr.referalOrCorpId">
          Referral / Corp Name
          <span>( {{ receiptAttr.referralOrCorpName }} ) </span>
      </p>
      <div class="inv-left-middle">
        <table class="table table-borderless p-m-0">
          <thead>
            <tr class="hightlist-total">
              <th class="text-center">UNIT</th>
              <th class="text-center">DESCRIPTION</th>
              <th class="text-center">PRICE</th>
              <th class="text-center">TOTAL</th>
            </tr>
          </thead>
          <tbody class="tbody-inv-cls">
            <template
              v-for="inv_service in  reverseReceipt(receiptAttr.invoiceServices)"
              :key="inv_service"
            >
              <tr class="text-center"  :class="{service_row_cls: inv_service.internalStatus == 'Parent'}">
                <td>{{ inv_service.qty }}x</td>
                <td class="text-left">
                  <span class="inv_check_status">{{
                    inv_service.checkStatus
                  }}</span>
                  {{ inv_service.serviceName }}
                </td>
                <td></td>
                <td>$ {{ fixLength(inv_service.totalBill) }}</td>
              </tr>
              <template v-for="items in reverseReceipt(inv_service.itemList)" :key="items">
                <tr
                  :class="{
                    'mark-checkout-express': inv_service.express == 'express',
                  }"
                  class="text-center"
                >
                  <td></td>
                  <td class="text-left">
                    <i
                      style="color: #004c97"
                      class="pi pi-arrow-circle-right"
                    ></i> <span v-html="items.itemName"></span>
                  </td>
                  <td class="text-left">
                    <b> ({{ items.itemQty }}x) </b> ${{
                      fixLength(items.price)
                    }}
                  </td>
                  <td> <span class="taxExemptCls" v-if="items.tax != 'not_exempt'"> {{items.tax}} </span></td>
                </tr>
                <tr
                  :class="{
                    'mark-checkout-express': inv_service.express == 'express',
                  }"
                  v-if="items.tag3 != ''"
                >
                  <td></td>
                  <td colspan="3">
                    <i class="pi pi-tag" aria-hidden="true"></i>
                    <i>{{ items.tag3 }} </i>
                  </td>
                </tr>
              </template>

              <tr
                :class="{
                  'mark-checkout-express': inv_service.express == 'express',
                }"
                v-if="inv_service.express == 'express'"
              >
                <td></td>
                <td colspan="3">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <span> Express 15%</span>
                  <i class="pull-right">
                    Due {{ formatDate(inv_service.dueDate) }}
                    {{ formatTime(inv_service.dueTime) }}
                  </i>
                </td>
              </tr>

              <tr
                :class="{
                  'mark-checkout-express': inv_service.express == 'express',
                }"
                v-if="inv_service.discountId != 0"
              >
                <td></td>
                <td colspan="3">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i>{{
                    inv_service.discountName +
                    " " +
                    inv_service.discount +
                    " " +
                    inv_service.discountMethod
                  }}</i>
                </td>
              </tr>

              <tr
                :class="{
                  'mark-checkout-express': inv_service.express == 'express',
                }"
                v-if="inv_service.tag1"
              >
                <td></td>
                <td colspan="3">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i>{{ inv_service.tag1 }}</i>
                </td>
              </tr>

              <tr
                :class="{
                  'mark-checkout-express': inv_service.express == 'express',
                }"
                v-if="inv_service.tag2"
              >
                <td></td>
                <td colspan="3">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i>{{ inv_service.tag2 }}</i>
                </td>
              </tr>

              <tr
                :class="{
                  'mark-checkout-express': inv_service.express == 'express',
                }"
                v-if="inv_service.description"
              >
                <td></td>
                <td colspan="3">
                  <i class="pi pi-tag" aria-hidden="true"></i>
                  <i> {{ inv_service.description }}</i>
                </td>
              </tr>

              <tr class="inv-btm-line">
                <td class="p-0 m-0" colspan="4"></td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <table id="inv-left-bottom" class="table table-striped p-p-0 p-m-0">
        <tr
          v-if="receiptAttr.defectReason != ''"
          style="background-color: #c00; color: #fff"
        >
          <td>
            <b>Defect Reason (Redo)</b>
          </td>
          <td class="text-right">{{ receiptAttr.defectReason }}</td>
        </tr>
        <tr>
          <td>
            <b>Memo</b>
          </td>
          <td class="text-right">{{ receiptAttr.description }}</td>
        </tr>
        <tr>
          <td>
            <b>Due </b>
          </td>
          <td>
            <span
              v-if="receiptAttr.dueTime != '' && receiptAttr.dueDate"
              id="due_date_invoice"
              class="pull-right"
            >
              {{
                formatTime(receiptAttr.dueTime) +
                " " +
                formatDate(receiptAttr.dueDate)
              }}
            </span>
          </td>
        </tr>
        <tr>
          <td>
            <b>Subtotal</b>
          </td>
          <td>
            <span class="pull-right">${{ serviceTotalBill() }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b>Coupon </b>
          </td>
          <td>
            <span class="pull-right" >{{receiptAttr.discountName}} {{ receiptAttr.discountMethod }} {{ fixLength(receiptAttr.discount) }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b>Subtotal After Disc </b>
          </td>
          <td>
            <span class="pull-right" >$  {{ fixLength(receiptAttr.totalAfterDisc) }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <b class="text-left"> Total Disc ${{fixLength(receiptAttr.totalDiscount)}}  </b>
          </td>
          <td>
            <span class="pull-right">
              <b class="taxExemptCls" v-if="receiptAttr.canadaExempt == 'yes'"> Fed Exempt : Yes </b> 
              <b class="taxExemptCls p-mx-1" v-if="receiptAttr.provinceExempt == 'yes'"> Prov Exempt : Yes </b> 
              {{ receiptAttr.taxCode }}
            </span>
          </td>
        </tr>
        <tr class="hightlist-total">
          <th>
            <h3 class="p-m-0 p-p-1">Items : {{ receiptAttr.totalQty }}</h3>
          </th>
          <th class="text-right">
            <h3 class="p-m-0 p-p-1">
              Total : $
              <span id="item_total_inv">{{
                fixLength(receiptAttr.totalBill)
              }}</span>
            </h3>
          </th>
        </tr>
      </table>
      <div v-if="receiptAttr.pickupName != ''">
        <h6 style="background-color: #343a40; color: #fff" class="p-p-2 p-m-0">
          <i class="pi pi-file-o"></i>
            Customer Signature
            <span class="pull-right">{{receiptAttr.pickupName}}</span>
        </h6>
        <div class="col-md-12 p-p-0">
          <img style="width: 100%" :src="receiptAttr.customerSignature" alt="Customer Signature" />
        </div>
      </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";
import { camelCase } from "lodash";
import moment from "moment";
import Transaction from "../service/Transaction";

@Options({
  components: {},
  props: {
    receiptDetail: Object,
  },
  watch: {
    receiptDetail(obj) {
      this.previewReceipt(obj.receiptID);
    },
  },
})
export default class CheckoutReceipt extends Vue {
  private txnService;
  private corporateType = "";
  private receiptAttr = {
    id: "",
    scheduleName: "",
    customer: "",
    phone: "",
    referalOrCorp: "",
    accountType: "",
    referalOrCorpId: "",
    description: "",
    defectReason: "",
    dueTime: "",
    dueDate: "",
    discountMethod: "",
    discount: 0,
    taxName: "",
    taxMethod: "",
    taxAmount: 0,
    taxAmountPrice: 0,
    totalQty: 0,
    totalBill: 0,
    invoiceServices: [
      {
        totalBill: 0,
        express: 0,
        qty: 0,
        checkStatus: 0,
        serviceName: 0,
        itemList: [
          {
            itemName: "",
            itemQty: "",
            price: "",
            tag3: "",
          },
        ],
        dueDate: 0,
        dueTime: 0,
        discountId: 0,
        discount: 0,
        discountName: 0,
        discountMethod: 0,
        tag1: 0,
        tag2: 0,
        description: 0,
        pickupName: '',
        customerSignature: '',
      },
    ],
  };

  created() {
    this.txnService = new Transaction();
  }

  previewReceipt(receiptID) {
    this.txnService.getInvDetails(receiptID).then((data) => {
      const receipt = this.camelizeKeys(data);
      this.receiptAttr = receipt.invoiceDetails[0];
      //this.getCorporateType(this.receiptAttr.referalOrCorpId);
    });
  }

  // getCorporateType(corporateID) {
  //   if (this.receiptAttr.referalOrCorp == "Corporate") {
  //     this.txnService.getCorporateType(corporateID).then((data) => {
  //       this.corporateType = data;
  //     });
  //   }
  // }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };

  fixLength(amount) {
    amount = Number(amount);
    amount = amount.toFixed(2);
    return amount;
  }

  formatDate(value) {
    if (value) {
      return moment(String(value)).format("DD-MM-YYYY");
    }
  }

  formatTime(value) {
    if (value) {
      const time = moment.duration(value);
      return moment(String(time), "HH:mm").format("hh:mm A");
    }
  }

  serviceTotalBill() {
    let totalBill = 0;

    this.receiptAttr.invoiceServices.forEach((e) => {
      totalBill = totalBill + e.totalBill;
    });

    return totalBill.toFixed(2);
  }

  reverseReceipt(itemList)
  {
    const l = itemList.slice();
    return l.reverse();
  }
}
</script>

<style scoped>

.inv-left-middle
{
  flex-grow: 1;
  height:48vh;
  min-height:48vh;
  overflow-y: scroll;
}

table tr td {
  padding: 3px;
}

.checkoutReceiptHeight {

  height: 92.5vh;
  min-height: 92.5vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.invoice-top {
  padding: 5px;
  color: #fff;
  background-color: #004c97;
}

.service_row_cls {
  background-color: #74e2e2;
}

.hightlist-total th {
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  background-color: #004c97 !important;
  color: #fff;
}

.inv_check_status {
  background-color: green;
  padding: 1px;
  font-weight: bold;
  font-size: 14px;
  padding: 5px;
  color: #fff;
  margin: 1px;
}

.mark-checkout-express {
  background-color: #c00;
  color: #fff;
}


.taxExemptCls
{
  font-size: 12px;
  background-color:green;
  padding:0 5px;
  border-radius:3px;
  margin-top:3px;
  color:#fff;
}

</style>

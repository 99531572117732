<template>
    <footer class="footer">
      <div class="container text-center">
        <span >Copyright ©Stichit 2020 - 2022</span>
      </div>
    </footer>
</template>

<script lang="ts">
import {  Vue } from 'vue-class-component';

export default class Footer extends Vue {
}
</script>

<style scoped>
    .footer {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background-color: #004C97;
  color: #fff;
}

</style>
